import React, { useState, useEffect } from "react"
import { DateRangePicker } from "react-dates"
import "react-dates/lib/css/_datepicker.css"
import { connectRange } from "react-instantsearch-dom"
import moment from "moment"

// https://github.com/DeedMob/react-instantsearch-datepicker-widget/blob/master/DatePicker.js
// https://stackoverflow.com/questions/52571616/setting-the-default-startdate-and-enddate-properties-for-react-dates

const AlgoliaDateRangePicker = ({ refine }) => {
  const [dateRangeFocusedInput, setDateRangeFocusedInput] = useState(null)
  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment().add(3, "month"))

  const setToStartOfDay = (d) => {
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return d
  }

  const setToEndOfDay = (d) => {
    d.setHours(23)
    d.setMinutes(59)
    d.setSeconds(59)
    d.setMilliseconds(999)
    return d
  }

  const dateToUnix = (d, startOrEnd) => {
    let newDate = new Date(d)
    if (startOrEnd === "start") {
      setToStartOfDay(newDate)
      return newDate.getTime() / 1000
    } else if (startOrEnd === "end") {
      setToEndOfDay(newDate)
      return newDate.getTime() / 1000
    }
  }

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate)
    setEndDate(endDate)

    // refine range without max value to prefent max value greater than range error
    const range = {
      min: dateToUnix(startDate, "start"),
    }

    refine(range)
  }

  useEffect(() => {
    // define range with max value on first render to get a default endDate
    const range = {
      min: dateToUnix(startDate, "start"),
      max: dateToUnix(endDate, "end"),
    }

    refine(range)
  }, [])

  return (
    <DateRangePicker
      startDate={startDate} // momentPropTypes.momentObj or null,
      startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
      endDate={endDate} // momentPropTypes.momentObj or null,
      endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
      onDatesChange={handleDatesChange} // PropTypes.func.isRequired,
      focusedInput={null} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      focusedInput={dateRangeFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={(focusedInput) => setDateRangeFocusedInput(focusedInput)} // PropTypes.func.isRequired,
      startDatePlaceholderText="Start-Datum"
      endDatePlaceholderText="End-Datum"
    />
  )
}

export default connectRange(AlgoliaDateRangePicker)
