import React, { useEffect, useRef } from "react"

const WidgetEmbed = ({ html }) => {
  const WrapperTag = "div"
  const wrapperRef = useRef(null)

  useEffect(() => {
    if (!html) return

    const slotHtml = document.createRange().createContextualFragment(html)
    wrapperRef.current.innerHTML = ""
    wrapperRef.current.appendChild(slotHtml)
  }, [html])

  return <WrapperTag ref={wrapperRef}></WrapperTag>
}

export default WidgetEmbed
