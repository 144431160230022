// automatic written by ruby script .. please dont edit these comments!
// storyblok attributes:
//   venue -> option / Spielort
// end of automatic

import React, { useState, useEffect } from "react"
import Layout from "gatsby-theme-yum-components/src/components/modules/Layout"
import { Helmet } from "react-helmet"
import BlockWrapper from "gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Link from "gatsby-theme-yum-components/src/components/atoms/Link"
import * as moment from "moment"
import useStoryblokDatasource from "gatsby-theme-yum-components/src/hooks/useStoryblokDatasource"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faMapPin,
  faTag,
  faInfoSquare,
  faCalendarPen,
} from "@fortawesome/pro-solid-svg-icons"
import useModal from "gatsby-theme-yum-components/src/hooks/useModal"
import Button from "gatsby-theme-yum-components/src/components/atoms/Button"
import Richtext from "gatsby-theme-yum-components/src/components/atoms/Richtext"
import ThemeLogo from "../../components/modules/ThemeLogo"
import "react-dates/initialize"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  MenuSelect,
  Configure,
  connectHits,
} from "react-instantsearch-dom"
import AlgoliaDateRangePicker from "gatsby-theme-yum-components/src/components/technical/AlgoliaDateRangePicker"
import Image from "gatsby-theme-yum-components/src/components/atoms/Image"
import menuItems from "../../components/constants/menuItems"
import WidgetEmbed from "gatsby-theme-yum-components/src/components/technical/WidgetEmbed"

import ICalendarLink from "react-icalendar-link"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

const SchedulePage = ({ content, location }) => {
  const { Modal, setShowModal } = useModal()
  const [activeItem, setActiveItem] = useState({})
  const [filter, setFilter] = useState("")
  const [eventLocation, setEventLocation] = useState("")

  let submenuItems = {}
  submenuItems.headline = "Veranstaltungen"
  submenuItems.filters = [
    { title: "Alle Veranstaltungen", filter: "", value: filter },
    { title: "Für Kinder", filter: "forKids:true", value: filter },
    { title: "Am Wochenende", filter: "onWeekend:true", value: filter },
  ]

  const handleFilter = (value) => {
    setFilter(value)
  }

  useEffect(() => {
    if (location !== undefined) {
      const result =
        location &&
        location !== "undefined" &&
        location.pathname &&
        location.pathname.match(/\/([^/]+)(\/?)(.?)/)
      setEventLocation(result && result[1])
    }
  }, [eventLocation, location, filter])

  const { storyblokDatasourceLookup } = useStoryblokDatasource()
  const [themeLogo] = ThemeLogo(eventLocation)

  const dynamicFormbuilderUrl = function (datetime, title) {
    return (
      '<script type="text/javascript" defer src="https://form.123formbuilder.com/embed/6038198.js" data-custom-vars="control99525410=' +
      title +
      "&control99525414=" +
      datetime +
      '" data-role="form" data-default-width="650px"></script>'
    )
  }
  const specialFormbuilderUrl = function (datetime, title) {
    return (
      '<script type="text/javascript" defer src="https://form.123formbuilder.com/embed/6070873.js" data-custom-vars="control100135187=' +
      title +
      "&control100135188=" +
      datetime +
      '" data-role="form" data-default-width="650px"></script>'
    )
  }

  const Hits = connectHits(({ hits }) => {
    const unrangedHits = hits.filter((hit) => hit.ranged === "false")
    const rangedHits = hits.filter((hit) => hit.ranged === "true")

    return (
      <>
        {unrangedHits.map((hit, index) => {
          return (
            <div className="pl-4 mb-8 shadow-lg lg:h-52 xl:h-44" key={index}>
              <div className="flex flex-row flex-wrap h-full -mx-grid">
                <div className="w-4/12 mb-4 md:w-2/12 px-grid">
                  <div className="prose text-right">
                    <div className="text-xs leading-loose text-gray-600">
                      {moment(hit.date).format("dddd")}
                    </div>
                    <div className="text-3xl font-bold leading-normal">
                      {moment(hit.date).format("DD")}
                    </div>
                    <div className="">{moment(hit.date).format("MMM")}</div>
                    <div className="text-xs text-gray-600">
                      {moment(hit.date).format("HH:mm")}&nbsp;Uhr
                    </div>
                    <div className="text-xs text-gray-600">
                      {hit.duration && `(${hit.duration})`}
                    </div>
                  </div>
                </div>
                <div className="order-last w-full mb-4 md:order-2 md:w-6/12 px-grid">
                  <div className="flex flex-row flex-wrap h-full -mx-grid">
                    <div className="w-full px-grid">
                      <div className="text-gray-600">{hit.kicker}</div>
                      <div className="text-xl font-bold leading-relaxed font-display group-hover:text-primary">
                        {hit.title}
                      </div>
                    </div>
                    {hit.price_range?.length > 0 ? (
                      <>
                        <div className="w-3/6 lg:w-4/6 px-grid">
                          <div className="flex flex-col justify-between h-full">
                            <div className="text-xs leading-loose text-grey-600">
                              <span className="mr-2">
                                <FontAwesomeIcon
                                  icon={faMapPin}
                                  fixedWidth
                                  size="1x"
                                  className="mr-1 mt-0.5 text-gray-600"
                                />
                                {storyblokDatasourceLookup(
                                  "venues",
                                  hit.location
                                )}
                              </span>
                              {hit.category?.length > 0 && (
                                <span className="mr-2">
                                  <FontAwesomeIcon
                                    icon={faTag}
                                    fixedWidth
                                    size="1x"
                                    className="mr-1 mt-0.5 text-gray-600"
                                  />
                                  {hit.category
                                    ?.map((category) =>
                                      storyblokDatasourceLookup(
                                        "categories",
                                        category
                                      )
                                    )
                                    .join(", ")}
                                </span>
                              )}
                              {hit.infos?.length > 0 && (
                                <span className="mr-2">
                                  <FontAwesomeIcon
                                    icon={faInfoSquare}
                                    fixedWidth
                                    size="1x"
                                    className="mr-1 mt-0.5 text-gray-600"
                                  />
                                  {hit.infos
                                    ?.map((info) =>
                                      storyblokDatasourceLookup("infos", info)
                                    )
                                    .join(", ")}
                                </span>
                              )}
                              {hit.venue?.length > 0 && (
                                <span className="mr-2">
                                  <FontAwesomeIcon
                                    icon={faMapPin}
                                    fixedWidth
                                    size="1x"
                                    className="mr-1 mt-0.5 text-gray-600"
                                  />
                                  {storyblokDatasourceLookup(
                                    "venues",
                                    hit.venue
                                  )}
                                </span>
                              )}
                            </div>
                            <Link
                              link={`/${hit.full_slug}`}
                              className="self-start mt-2 btn btn-primary"
                            >
                              Mehr Infos
                            </Link>
                          </div>
                        </div>
                        <div className="w-3/6 lg:w-2/6 px-grid">
                          <div className="flex flex-col justify-between h-full">
                            <div className="text-xs leading-loose text-grey-600">
                              {hit.price_range?.length > 0 && hit.price_range}
                            </div>

                            {(hit.ticketLink !== "" ||
                              hit.formularLink?.cached_url?.length > 0 ||
                              hit.dynamic_formular !== "") && (
                              <button
                                className="self-start mt-2 btn btn-secondary"
                                onClick={() => {
                                  setActiveItem(hit)
                                  setShowModal(true)
                                }}
                              >
                                Tickets
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="w-full px-grid">
                          <div className="flex flex-col justify-start h-full">
                            <div className="text-xs leading-loose text-grey-600">
                              <span className="mr-2">
                                <FontAwesomeIcon
                                  icon={faMapPin}
                                  fixedWidth
                                  size="1x"
                                  className="mr-1 mt-0.5 text-gray-600"
                                />
                                {storyblokDatasourceLookup(
                                  "venues",
                                  hit.location
                                )}
                              </span>
                              {hit.category?.length > 0 && (
                                <span className="mr-2">
                                  <FontAwesomeIcon
                                    icon={faTag}
                                    fixedWidth
                                    size="1x"
                                    className="mr-1 mt-0.5 text-gray-600"
                                  />
                                  {hit.category
                                    ?.map((category) =>
                                      storyblokDatasourceLookup(
                                        "categories",
                                        category
                                      )
                                    )
                                    .join(", ")}
                                </span>
                              )}
                              {hit.infos?.length > 0 && (
                                <span className="mr-2">
                                  <FontAwesomeIcon
                                    icon={faInfoSquare}
                                    fixedWidth
                                    size="1x"
                                    className="mr-1 mt-0.5 text-gray-600"
                                  />
                                  {hit.infos
                                    ?.map((info) =>
                                      storyblokDatasourceLookup("infos", info)
                                    )
                                    .join(", ")}
                                </span>
                              )}
                              {hit.venue?.length > 0 && (
                                <span className="mr-2">
                                  <FontAwesomeIcon
                                    icon={faMapPin}
                                    fixedWidth
                                    size="1x"
                                    className="mr-1 mt-0.5 text-gray-600"
                                  />
                                  {storyblokDatasourceLookup(
                                    "venues",
                                    hit.venue
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-3/6 lg:w-4/6 px-grid">
                          <div className="flex flex-col justify-end h-full">
                            <div>
                              <ICalendarLink
                                className="self-start mr-4 btn btn-secondary tooltip"
                                event={{
                                  title: hit.title,
                                  description: `Weitere Informationen: ${
                                    window && window.location.origin
                                  }/${hit.full_slug}`,
                                  startTime: hit.date,
                                  endTime: "",
                                  location: storyblokDatasourceLookup(
                                    "venues",
                                    hit.location
                                  ),
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faCalendarPen}
                                  fixedWidth
                                  size="1x"
                                  className="inline-block"
                                />
                                <div className="tooltip-text">
                                  Termin in Kalender übernehmen.
                                </div>
                              </ICalendarLink>
                              <Link
                                link={`/${hit.full_slug}`}
                                className="self-start mt-2 btn btn-primary"
                              >
                                Mehr Infos
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="w-3/6 lg:w-2/6 px-grid">
                          <div className="flex flex-col justify-end h-full">
                            {(hit.ticketLink !== "" ||
                              hit.cinetixx_link !== "" ||
                              hit.formularLink?.cached_url?.length > 0 ||
                              hit.dynamic_formular !== "") && (
                              <button
                                className="self-start mt-2 btn btn-secondary"
                                onClick={() => {
                                  setActiveItem(hit)
                                  setShowModal(true)
                                }}
                              >
                                Tickets
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="w-8/12 mb-4 md:mb-0 md:order-last md:w-4/12 px-grid">
                  <div className="flex w-full h-full lg:h-52 xl:h-44">
                    {hit.image && (
                      <Image
                        className="object-cover w-full h-full"
                        image={hit.image}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        {rangedHits.map((hit, index) => {
          return (
            <div className="pl-4 mb-8 shadow-lg lg:h-52 xl:h-44" key={index}>
              <div className="flex flex-row flex-wrap h-full -mx-grid">
                <div className="w-4/12 mb-4 md:w-2/12 px-grid">
                  <div className="prose text-right">
                    <div className="">{hit.rangeText}</div>
                  </div>
                </div>
                <div className="order-last w-full mb-4 md:order-2 md:w-6/12 px-grid">
                  <div className="flex flex-row flex-wrap h-full -mx-grid">
                    <div className="w-full px-grid">
                      <div className="text-gray-600">{hit.kicker}</div>
                      <div className="text-xl font-bold leading-relaxed font-display group-hover:text-primary">
                        {hit.title}
                      </div>
                    </div>
                    <div className="w-3/6 lg:w-4/6 px-grid">
                      <div className="flex flex-col justify-between h-full">
                        <div className="text-xs leading-loose text-grey-600">
                          <span className="mr-2">
                            <FontAwesomeIcon
                              icon={faMapPin}
                              fixedWidth
                              size="1x"
                              className="mr-1 mt-0.5 text-gray-600"
                            />
                            {storyblokDatasourceLookup("venues", hit.location)}
                          </span>
                          {hit.category?.length > 0 && (
                            <span className="mr-2">
                              <FontAwesomeIcon
                                icon={faTag}
                                fixedWidth
                                size="1x"
                                className="mr-1 mt-0.5 text-gray-600"
                              />
                              {hit.category
                                ?.map((category) =>
                                  storyblokDatasourceLookup(
                                    "categories",
                                    category
                                  )
                                )
                                .join(", ")}
                            </span>
                          )}
                          {hit.infos?.length > 0 && (
                            <span className="mr-2">
                              <FontAwesomeIcon
                                icon={faInfoSquare}
                                fixedWidth
                                size="1x"
                                className="mr-1 mt-0.5 text-gray-600"
                              />
                              {hit.infos
                                ?.map((info) =>
                                  storyblokDatasourceLookup("infos", info)
                                )
                                .join(", ")}
                            </span>
                          )}
                          {hit.venue?.length > 0 && (
                            <span className="mr-2">
                              <FontAwesomeIcon
                                icon={faMapPin}
                                fixedWidth
                                size="1x"
                                className="mr-1 mt-0.5 text-gray-600"
                              />
                              {storyblokDatasourceLookup("venues", hit.venue)}
                            </span>
                          )}
                        </div>
                        <Link
                          link={`/${hit.full_slug}`}
                          className="self-start mt-2 btn btn-primary"
                        >
                          Mehr Infos
                        </Link>
                      </div>
                    </div>
                    <div className="w-3/6 lg:w-2/6 px-grid">
                      <div className="flex flex-col justify-between h-full">
                        <div className="text-xs leading-loose text-grey-600">
                          {hit.price_range?.length > 0 && hit.price_range}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-8/12 mb-4 md:mb-0 md:order-last md:w-4/12 px-grid">
                  <div className="flex w-full h-full lg:h-52 xl:h-44">
                    {hit.image && (
                      <Image
                        className="object-cover w-full h-full"
                        image={hit.image}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  })
  return (
    <Layout
      submenuItems={submenuItems}
      menuItems={menuItems}
      venue={content.venue}
      location={location}
      submenuAction={handleFilter}
    >
      <Helmet
        bodyAttributes={{
          class: `theme-${content.venue} font-body antialiased`,
        }}
      />
      <div className="pb-8 lg:pb-0">
        <div className="absolute top-0 right-0 z-50 w-1/2 h-auto px-8 pt-4 ml-auto opacity-100 lg:w-72">
          {themeLogo && (
            <div className="relative w-full pb-24 ml-auto sm:w-48 lg:w-56 lg:pb-32">
              <div className="absolute top-0 right-0 w-auto h-full">
                <Image
                  image={themeLogo}
                  className={`object-contain object-right-top h-full w-full`}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <BlockWrapper block={{}} blockPadding="pb-0 pt-16 lg:pb-16 lg:pt-10">
        <h1 className="text-3xl font-bold font-display">Veranstaltungen</h1>
      </BlockWrapper>
      <BlockWrapper block={{}}>
        <InstantSearch
          indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
          searchClient={searchClient}
        >
          <Configure filters={filter} />
          <div className="pt-4 pb-8">
            <form
              action="true"
              role="search"
              noValidate="novalidate"
              className="inline-block mb-4 mr-4 text-base ais-SearchBox-form"
            >
              <AlgoliaDateRangePicker attribute="timestamp" />
            </form>
            <MenuSelect
              attribute="location"
              translations={{ seeAllOption: "Spielort" }}
              defaultRefinement={eventLocation ? eventLocation : ""}
              transformItems={(items) =>
                items.map((item, index) => {
                  //console.log(item)
                  return {
                    ...item,
                    key: index,
                    label: storyblokDatasourceLookup("venues", item.label),
                  }
                })
              }
              className="inline-block mb-4 mr-4"
            />
            <MenuSelect
              attribute="category"
              translations={{ seeAllOption: "Genre" }}
              transformItems={(items) =>
                items.map((item, index) => ({
                  ...item,
                  key: index,
                  label: storyblokDatasourceLookup("categories", item.label),
                }))
              }
              className="inline-block mb-4 mr-4"
            />
            <SearchBox
              translations={{ placeholder: "Suchen..." }}
              submit=""
              reset=""
              className="inline-block mb-4 mr-4"
            />
          </div>
          <Hits />
        </InstantSearch>
        <Modal>
          <div className="flex flex-row flex-wrap">
            {content.venue !== "kino-alte-muehle" && (
              <div className="w-full lg:w-1/2">
                <div className="prose">
                  <b>Kartenbüro Reservierung</b>
                  <br />
                  Klaus-Havenstein-Weg 1<br />
                  61118 Bad Vilbel
                  <br />
                  Tel: <a href="tel:06101559455">06101 / 55 94 55</a>
                  <br />
                  Mail:{" "}
                  <Link link="tickets@bad-vilbel.de">
                    tickets@bad-vilbel.de
                  </Link>
                </div>
              </div>
            )}
            <div
              className={`w-full ${
                content.venue !== "kino-alte-muehle" ? "lg:w-1/2" : ""
              }`}
            >
              {activeItem.formularLink &&
              typeof activeItem.formularLink === "object" &&
              (activeItem.formularLink.url?.length > 0 ||
                activeItem.formularLink.cached_url?.length > 0) ? (
                <>
                  <div className="mb-2">
                    <b>Ticketreservierung</b>
                  </div>
                  {activeItem.price?.length > 0 && (
                    <Richtext
                      className="pb-4"
                      text={`**Preise:** ${activeItem.price}`}
                    />
                  )}

                  {!activeItem.dynamic_formular && (
                    <Button>
                      <Link link={activeItem.formularLink} externalIcon={true}>
                        Zum Formular
                      </Link>
                    </Button>
                  )}
                </>
              ) : !activeItem.dynamic_formular &&
                activeItem.cinetixx_link?.length > 0 ? (
                <iframe
                  className="w-full"
                  src={activeItem.cinetixx_link}
                  height="1100px"
                />
              ) : activeItem.ticketLink?.length > 0 ? (
                <>
                  <div className="mb-2">
                    <b>Ticketbestellung </b>
                  </div>
                  {activeItem.price?.length > 0 && (
                    <Richtext
                      className="pb-4"
                      text={`**Preise:** ${activeItem.price}`}
                    />
                  )}
                  {!activeItem.dynamic_formular && (
                    <Button>
                      <Link link={activeItem.ticketLink} externalIcon={true}>
                        Zu Frankfurtticket
                      </Link>
                    </Button>
                  )}
                </>
              ) : (
                <></>
              )}

              {activeItem.dynamic_formular === "dynamic" && (
                <WidgetEmbed
                  html={dynamicFormbuilderUrl(
                    moment(activeItem.date).format("DD.MM.YYYY HH:mm"),
                    activeItem.title
                  )}
                />
              )}
              {activeItem.dynamic_formular === "special" && (
                <WidgetEmbed
                  html={specialFormbuilderUrl(
                    moment(activeItem.date).format("DD.MM.YYYY HH:mm"),
                    activeItem.title
                  )}
                />
              )}
            </div>
          </div>
        </Modal>
      </BlockWrapper>
    </Layout>
  )
}

export default SchedulePage
